import React from 'react'
import classnames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundImg from '../../shared/BackgroundImg'
import * as styles from './styles.module.scss'

const DotaSuccess = () => (
  <section className={styles.heroWrapper}>
    <div className={classnames(styles.hero, 'col-centered')}>
      <div>EXCITING TIMES AHEAD...</div>
      <h2 className={styles.text}>
        <span className='text-color--primary'>
          Your payment has been processed! :)
        </span>
        <hr />
      </h2>
      <div>
        <a className='button' href={`http://dota2.shadow.gg`}>
          Access Shadow Dota now
        </a>
      </div>
    </div>
    <StaticQuery
      query={graphql`
        {
          placeholderImage: file(relativePath: { eq: "group-2.jpg" }) {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => (
        <BackgroundImg
          image={data.placeholderImage.childImageSharp.gatsbyImageData}
        />
      )}
    />
  </section>
)

export default DotaSuccess
